<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="productions"
      :items-per-page="25"
      class="elevation-3 mx-lg-10"
      mobile-breakpoint="100"
      calculate-widths
      hide-default-footer
    >
      <template v-slot:top>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="px-8 py-8"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari"
              color="purple"
              single-line
              hide-details
              clearable
              @keyup.enter.native="filterData"
              @click:append="filterData"
              @click:clear="filterClear"
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <div class="text-center my-5 py-3">
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="purple"
            @input="onChangePage"
          />
          <span style="font-size: 12px; font-style: italic;">
            Page {{ page }} of {{ pageCount || 1 }}
          </span>
        </div>
        <v-row class="mt-n16">
          <v-col class="text-right pr-8">
            <span style="font-size: 12px; font-style: italic;">
              {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
            </span>
          </v-col>
        </v-row>
      </template>

      <template
        v-if="isAllowReview"
        v-slot:[`item.actions`]="{ item }"
      >
        <v-badge
          v-if="item.status === 1"
          color="error"
          overlap
          content="!"
        >
          <button
            class="custom-button"
            @click="detailButtonClick(item)"
          >
            <v-icon
              id="button-icon"
            >
              mdi-eye
            </v-icon>
          </button>
        </v-badge>
        <button
          v-else
          class="custom-button"
          @click="detailButtonClick(item)"
        >
          <v-icon
            id="button-icon"
          >
            mdi-eye
          </v-icon>
        </button>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      search: '',
      headers: [
        { text: 'Perusahaan', align: 'start', value: 'prt_company_name_legal', sortable: true },
        { text: 'Kode Produksi', align: 'start', value: 'code', sortable: true },
        { text: 'Nama Aset', value: 'ass_name', sortable: true },
        { text: 'Tanggal Pemesanan', value: 'created_time', sortable: true },
        { text: 'Status', value: 'status_string', sortable: true },
        { text: '', value: 'actions', sortable: false },
      ],
      productions: [],
      isAllowReview: false,
      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,

      // action button:
      buttonColor: '#FAFAFA',
      iconColor: 'purple',
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
    },

    methods: {
      initialize (page, limit, filter) {
        // check user menus
        const userMenus = localStorage.getItem('userMenus').split(',')
        if (userMenus.includes('PRODUKSI_REVIEW')) {
          this.isAllowReview = true
        }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/t/production', this.$qs.stringify(requestBody)).then((res) => {
          if (res.data.status === 200) {
            vm.productions = res.data.data.list
            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        }).catch((e) => {
        })
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      detailButtonClick (item) {
        this.$router.push({ name: 'ProductionDetail', params: { id: item.hash } })
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },

      mouseEnter () {
        // this.buttonColor = '#7A1878'
        // this.iconColor = '#FAFAFA'
      },

      mouseLeave () {
        // this.buttonColor = '#FAFAFA'
        // this.iconColor = '#7A1878'
      },
    },
  }
</script>

<style scoped>
  .custom-button {
    background-color:#FAFAFA;
    border: 1px solid #F2F2F2;
    color: #FAFAFA;
    border-radius: 4px;
    margin: 2px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    transition: 0.3s;
  }

  .custom-button:hover {
    background-color: #7A1878;
  }

  .custom-button:hover .theme--light.v-icon {
    color: white;
  }

  .theme--light.v-icon {
    color: #7A1878;
  }

  .v-badge__badge {
    height: 15px;
    min-width: 15px;
    padding: 2px 6px;
  }
</style>
